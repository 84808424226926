import {onDomReady} from "../../components/dynamic/observer";

class SuspendedFilter {
    constructor(form) {
        this.form = form;
        this.rows = document.querySelectorAll('[data-filter-item]');
        this.init();
    }

    init() {
        this.initSelect();
        this.bindInputs();
    }

    initSelect() {
        const select = this.form.querySelector('[data-select]');
        const storesValues = [];
        this.rows.forEach((row) => {
            const value = row.dataset.store;
            if (!storesValues.includes(value)) {
                storesValues.push(value);
            }
        })

        storesValues.forEach((e) => {
            const option = document.createElement('option');
            option.textContent = e;
            option.value = e;
            select.appendChild(option);
        })
    }

    bindInputs() {
        const inputs = this.form.querySelectorAll('[data-filter-by]');
        inputs.forEach((input) => {
            input.addEventListener('change', (e) => {
                const formData = new FormData(this.form);
                const dataObject = {};
                formData.forEach((value, key) => {
                    dataObject[key] = value;
                });

                this.filter(dataObject);
            })
        })
    }

    filter(data) {
        // Убираем скрытые строки
        this.rows.forEach((line) => {
            const target = line.closest('tr');
            target.classList.remove('_hide');
        });

        // Применяем фильтр
        this.rows.forEach((line) => {
            const target = line.closest('tr');

            // Фильтр по складу
            if (data.store && data.store !== 'all' && line.dataset.store !== data.store) {
                target.classList.add('_hide');
            }

            if (!data.old && (data.dateFrom || data.dateTo)) {
                // Фильтр по дате
                const rowDate = parseInt(line.dataset.date, 10);
                const fromDate = data.dateFrom ? Math.floor(new Date(data.dateFrom).getTime() / 1000) : null;
                const toDate = data.dateTo ? Math.floor(new Date(data.dateTo).getTime() / 1000) : null;

                if (!rowDate || (rowDate && ((fromDate && rowDate < fromDate) || (toDate && rowDate > toDate)))) {
                    target.classList.add('_hide');
                }
            }

            // Фильтр по старым
            if (data.old) {
                if (!line.dataset.old) {
                    target.classList.add('_hide');
                }
            }
        });
    }
}

onDomReady(() => {
    const form = document.querySelector('[data-filter-form]');
    if (form) {
        new SuspendedFilter(form);
    }
})