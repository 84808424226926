import axios from "axios";

const pricesTable = document.querySelector('[data-prices-table]');
if (pricesTable) {
  document.addEventListener('modal-form:success', async () => {
    const result = await axios.get(window.location.href);
    const container = document.createElement('div');
    container.innerHTML = result.data;
    document.querySelector('[data-prices-table]').innerHTML = container.querySelector('[data-prices-table]').innerHTML;
    document.querySelector('[data-payslips-table]').innerHTML = container.querySelector('[data-payslips-table]').innerHTML;
  });
}

// find all [data-group-toggle] elements, than find closest [data-group] and toggle them class _hidden
document.querySelectorAll('[data-group-toggle]').forEach((el) => {
  el.addEventListener('click', (event) => {
    const group = event.target.closest('[data-group]');
    const groupName = group.dataset.group;

    document.querySelectorAll(`[data-group="${groupName}"]`).forEach((groupEl) => {
        groupEl.classList.toggle('_hidden');
    });
  });
});