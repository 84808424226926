import axios from "axios";

const monthTable = document.querySelector('[data-month-table]');
if (monthTable) {
  document.addEventListener('modal-form:success', async () => {
    const result = await axios.get(window.location.href);
    const container = document.createElement('div');
    container.innerHTML = result.data;
    document.querySelector('[data-month-table]').innerHTML = container.querySelector('[data-month-table]').innerHTML;
    document.querySelector('[data-penalty-table]').innerHTML = container.querySelector('[data-penalty-table]').innerHTML;
  });
}
