require('../scss/app.scss');
require('./svg/svg.js');

require('../fonts/Roboto_Flex/stylesheet.css');

window.noZensmooth = true;

// require('../vue/app');
// require('@vueform/slider/themes/default.css')

require('./sliders/sliders.js');

require('./common/true-vh');
require('./common/goals2');
require('./common/js-validation');
require('./common/links');
require('./common/switcher');
require('./common/main');
require('./common/pagination');
require('./common/respond');
require('./common/need-animation');
require('./common/webp-polyfill');
require('./common/text-wrap-creator');
require('./common/photoswipe');
require('./common/form-autosave');

require('./accordion/initializator');
require('./accordion/switchable-accordion');

require('./field/phone.js');
require('./field/floating.js');
require('./field/multi-uploadable.js');

// require('./field/range.js'); // Раскоментить при необходимости
require('../components/smart-tabs/smart-tabs');
require('./store/manager-edit');
require('./store/administrator-edit');
require('./sudpended-filter/SuspendedFilter');