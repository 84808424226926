import {singleInit} from "../../components/dynamic/bind";
import axios from "axios";
import _ from 'lodash';

async function handleAutoSave(form)
{
    const url = form.getAttribute('action');
    const method = form.getAttribute('method') ? form.getAttribute('method') : 'get';
    const data = new FormData(form);

    axios({
        method,
        url,
        data,
        headers: { "Content-Type": "multipart/form-data" },
    })
}

const bindAutosave = function () {
    document.querySelectorAll('[data-form-autosave="true"]').forEach((form) => {
        singleInit(form, 'autoform', () => {

            form.querySelectorAll('input, textarea, select').forEach((input) => {
                input.addEventListener('input', _.debounce(() => {
                    handleAutoSave(form);
                }, 300));
            });
        });
    });
}

document.addEventListener('DOMContentMutated', bindAutosave);
document.addEventListener('DOMContentLoaded', bindAutosave);
